<div class="page-container">
  <div class="page">
    <h1>Connect in-lite to Google</h1>
    <h2>Make your in-lite garden even smarter</h2>

    <label>
      <input
        [ngClass]="{
          'invalid-box': email.invalid && (email.dirty || email.touched)
        }"
        class="email-input"
        type="email"
        placeholder="Email address"
        [formControl]="email"
      />
    </label>

    <div
      class="email-validation error-message"
      *ngIf="email.invalid && (email.dirty || email.touched || submitted)"
    >
      <div *ngIf="email.errors.required">Email is required.</div>

      <div *ngIf="email.errors.invalidEmail">Email is not valid.</div>
    </div>

    <inlite-primary-button
      class="request-button"
      (clickButton)="requestSignIn()"
      title="Request sign-in code"
      [isLoading]="isLoading"
    ></inlite-primary-button>
    <div class="error-message authorize-error" *ngIf="authorizeError">
      {{ authorizeError }}
    </div>
    <span class="disclaimer-text"
      >By signing in, you are authorizing Google to control your devices.</span
    >
  </div>
</div>

<div class="page">
  <h1>You received a sign-in code in your mailbox</h1>

  <h2>We've sent it to {{ email }}</h2>

  <div>
    <ng-otp-input
      (onInputChange)="onOtpChange($event)"
      [config]="{
        length: 6,
        allowNumbersOnly: true,
        containerClass: 'code-wrapper',
        inputClass: 'code-input'
      }"
    ></ng-otp-input>
  </div>
  <div class="code-validation" *ngIf="codeSubmittedInvalid">Invalid code.</div>

  <inlite-primary-button
    title="Sign in"
    (clickButton)="signIn()"
    [isLoading]="isSignLoading"
  ></inlite-primary-button>
  <div class="error-message login-error" *ngIf="loginError">
    {{ loginError }}
  </div>

  <inlite-secondary-button
    class="again-button"
    title="Send again"
    (clickButton)="onSendAgainClick()"
    [isLoading]="isRetryLoading"
  ></inlite-secondary-button>
  <div class="error-message authorizeError" *ngIf="authorizeError">
    {{ authorizeError }}
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'inlite-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.css']
})
export class SecondaryButtonComponent {
  @Input() title: string;
  @Output() clickButton = new EventEmitter<any>();
  @Input() isLoading: boolean;

  onButtonClick(event): void {
    this.clickButton.emit(event);
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { email } from '../../validators/input-validators';
import { AuthService } from '../../shared/auth/auth.service';

@Component({
  selector: 'inlite-request-code',
  templateUrl: './request-code.component.html',
  styleUrls: ['./request-code.component.css'],
})
export class RequestCodeComponent {
  email = new FormControl('', [Validators.required, email()]);
  submitted = false;
  authorizeError;
  redirectUri;
  state;
  isLoading = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
  ) {
    this.redirectUri = activatedRoute.snapshot.queryParamMap.get('redirect_uri');
    this.state = activatedRoute.snapshot.queryParamMap.get('state');
  }

  public async requestSignIn(): Promise<void> {
    this.authorizeError = undefined;
    this.submitted = true;
    if (!this.email.valid) {
      return;
    }
    this.isLoading = true;

    const emailValue = this.email.value;
    const stateToPass = { email: emailValue, redirectUri: this.redirectUri, state: this.state };
    try {
      await this.authService.authorize(emailValue);
      await this.router.navigate(['/sign-in'], { state: stateToPass });
    } catch (err) {
      this.handleAuthorizeError(err);
    }
    this.isLoading = false;
  }

  private handleAuthorizeError(err) {
    const error = err?.error || {};
    const { message } = error;
    this.authorizeError = message ? message : 'Something went wrong';
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginResult } from './login-result.interface';
import { CreateAuthCodeDto } from './dto/create-auth-code.dto';

@Injectable()
export class AuthService {

  constructor(private readonly http: HttpClient) {
  }

  public authorize(email: string): Promise<any> {
    const path = `${environment.MAIN_API_URL}/v2/users/authorize`;
    const body = { email, language: 'en-us' };
    return this.http.post<any>(path, body).toPromise();
  }

  public login(email: string, code: string): Promise<LoginResult> {
    const path = `${environment.MAIN_API_URL}/users/login`;
    const body = { email, code };
    return this.http.post<LoginResult>(path, body).toPromise();
  }

  public async createAuthCode(dto: CreateAuthCodeDto): Promise<string> {
    const path = `${environment.AUTH_ASSISTANT_API_URL}/auth/auth-code`;
    const result = await this.http.post<{ authCode: string }>(path, dto).toPromise();
    return result.authCode;
  }
}

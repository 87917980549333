<div class="page">
  <h1>Select the garden you want to connect</h1>

  <h2>Signed in as: {{email}}</h2>

  <select name="gardens" class="garden-select" [(ngModel)]="selected">
    <option class="gardenOption" [value]="garden._id" *ngFor="let garden of gardens" value={{garden.name}}>{{garden.name}}</option>
  </select>

  <inlite-primary-button class="connect-button" (clickButton)="connect()" title="Connect to Google" [isLoading]="isLoading"></inlite-primary-button>

  <div class="error-message connect-error" *ngIf="connectError">{{connectError}}</div>
</div>



import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RequestCodeComponent } from '../pages/request-code/request-code.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from '../pages/sign-in/sign-in.component';
import { PrimaryButtonComponent } from '../components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../components/secondary-button/secondary-button.component';
import { HeaderComponent } from '../components/header/header.component';
import { GardenSelectComponent } from '../pages/garden-select/garden-select.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '../shared/auth/auth.service';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RequestCodeComponent,
    SignInComponent,
    GardenSelectComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }

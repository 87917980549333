import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth/auth.service';

@Component({
  selector: 'inlite-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent {
  email = '';
  signInCode = '';
  codeSubmittedInvalid = false;
  authorizeError;
  loginError;
  requiredCodeLength = 6;
  passedState;
  isSignLoading = false;
  isRetryLoading = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.passedState = this.router.getCurrentNavigation().extras?.state || {};
    const { email } = this.passedState;
    
    if (!email) {
      this.router.navigate(['/']);
    } else {
      this.email = email;
    }
  }

  public async signIn(): Promise<void> {
    this.isSignLoading = true;

    if (this.signInCode.length !== this.requiredCodeLength) {
      this.codeSubmittedInvalid = true;
      this.isSignLoading = false;
      return;
    }

    try {
      const loginResult = await this.authService.login(this.email, this.signInCode);
      const stateToPass = { ...this.passedState, loginResult };
      await this.router.navigate(['/garden-select'], { state: stateToPass });
    } catch (err) {
      this.handleLoginError(err);
    }
    this.isSignLoading = false;
  }

  private handleLoginError(err) {
    const error = err?.error || {};
    const { message } = error;
    this.loginError = message ? message : 'Something went wrong';
  }

  public async onSendAgainClick(): Promise<void> {
    this.isRetryLoading = true;
    this.authorizeError = '';
    try {
      await this.authService.authorize(this.email);
    } catch (err) {
      this.handleAuthorizeError(err);
    }
    this.isRetryLoading = false;
  }

  private handleAuthorizeError(err) {
    const error = err?.error || {};
    const { message } = error;
    this.authorizeError = message ? message : 'Something went wrong';
  }

  public onOtpChange(otpCode: string) {
    if (otpCode.length === this.requiredCodeLength) {
      this.signInCode = otpCode;
      this.signIn();
    } else {
      this.codeSubmittedInvalid = false;
      this.authorizeError = '';
      this.loginError = '';
    }
  }
}
